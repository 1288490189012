@import url("responsive.css");
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700&display=swap');

* {padding: 0; margin: 0; font-family: "Heebo", sans-serif !important;}

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiList-root .MuiButtonBase-root span {font-size: 14px;}
.makeapp-1sumxir-MuiFormLabel-root-MuiInputLabel-root {right: -10px !important;}

footer {float: left;}
footer img {max-width: 120px;}

.statusPage {background: url('../img/1.jpg'); background-repeat: no-repeat; background-attachment: fixed; background-size: cover; background-position: center top;}
.statusPage h2 {color: #fff; font-size: 48px; margin-bottom: 2rem;}
.statusPage #logo {max-width: 160px; margin-bottom: 2rem;}
.statusPage header {float: right; width: 100%; text-align: center; margin: 2rem 0; display: flex; flex-direction: column; align-items: center; justify-content: center;}
.statusPage header > time {display: block; background-color: flex; font-size: 32px; background-color: #dba118; padding: 0 2rem; border-radius: 5px;}

.statusPage h4 {color: #fff; font-size: 26px; border-top: 2px solid #dba118; border-bottom: 2px solid #dba118; text-align: center; height: 40px;}
.statusPage .sidebar {background-color: #444; height: 100%; padding: 0 .8rem;}
.statusPage .sidebar > div {height: calc(50% - 44px); color: #fff; font-size: 22px; text-align: center;}
.statusPage .sidebar > div p {padding: 2rem 0;}
.vehicle {background-color: #333 !important; box-shadow: 0 0 15px #000 !important; min-height: 280px; text-align: center; color: white !important; font-size: 18px;}

.statusPage footer {color: white; position: absolute; bottom: 2rem;}
.statusPage footer img {max-width: 16px; margin-right: 1rem;}

.uploadButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.uploadButton:hover {
  background-color: #45a049;
}

.uploadButton:active {
  background-color: #3e8e41;
}


@media (max-width: 768px) {.sidebar {display: none;}}

@media (max-width: 768px) { footer {display: none; }}