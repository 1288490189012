body.loginPage {background: url('../img/background.jpg') no-repeat; background-size: cover;}
.background-overlay {background-color: black; opacity: .8; position: fixed; top: 0; left: 0; width: 100%; height: 100vh; z-index: -10;}
.App {display: flex; align-items: center; flex-direction: column; justify-content: center; height: 100vh;}
.App > h1 {color: #fff; font-size: 40px; font-weight: 600; margin: 10px 0 0; line-height: 1;}
.App > h2 {color: gray; font-size: 18px; font-weight: 500; margin: 0;}
.App > .wrapper {padding: 40px 5%;}
.App > .wrapper > form > .row > .col > :not(.MuiPaper-root) * {color: #fff;}
.App > .wrapper > form > .row > .col {margin-top: 1rem;}
.App > .wrapper .makeapp-ghsjzk-MuiInputBase-root-MuiInput-root::before {border-bottom-color: rgba(255, 255, 255, .2);}
.App > .wrapper .makeapp-ghsjzk-MuiInputBase-root-MuiInput-root::after {border-bottom-color: #f8b319;}
.App > .wrapper .makeapp-1c2i806-MuiFormLabel-root-MuiInputLabel-root[data-shrink=true] {color: #f8b319;}
.App > .wrapper button {margin-top: 10px; font-size: 17px;}
.App > .wrapper .MuiAlert-root {margin-top: 10px;}
/* body.status {padding-left: 10rem; padding-right: 10rem;} */
body.status > div {background-color: rgba(0,0,0,.8);}